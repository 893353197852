import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header'
import Footer from '../components/Footer'

import HeadtoHeadFixt from '../components/HeadtoHeadFixt'

import HeadTags from '../components/Headtags'
import SnackAd2 from '../components/SnackAd2'
import SnackAd from '../components/SnackAd'
import {Container,Row} from "react-bootstrap"

// import Amplify from 'aws-amplify';
// import config from '../aws-exports';

// Amplify.configure(config);

const H2Hfixt = ({data}) => {
   const post = data.markdownRemark

   return (
  <>
    <HeadTags />
    <div style ={{position:"relative", minHeight:"100vh"}}>
    <Row>
        <SnackAd2 ad_type = "leader" ad_num = {1}/>
    </Row>
      <Header />
      <HeadtoHeadFixt lgCode = {post.frontmatter.code}/>
      <Container fluid>
        <Row>
          <SnackAd ad_type = "leader" ad_num = {4}/>
        </Row>
      </Container>
      <Footer />
    </div>
  </>
)
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug} }) {
      html
      frontmatter {
        title
        keywords
        code
        type
      }
    }
  }
`

export default H2Hfixt
